import React, { Fragment, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminCouponsAction, verifyCouponAction } from '../../redux/actions/couponActions';
import { toast } from 'react-toastify';
import { VERIFY_COUPON_RESET } from '../../redux/constants/couponConstants';
import { completeOnboardAction } from '../../redux/actions/onboardingActions';
import { CREATE_NEW_ONBOARD_USER_RESET } from '../../redux/constants/onboardingConstants';
import Spinner from '../../components/Spinner/Spinner';
import InputCoupon from '../../components/formInputs/InputBox/InputCoupon';
const stripePromise = loadStripe('pk_test_HLKSK1hyuVsxEIZxIsEivNEj00RsqCdrBq');

const CheckOut = ({ state, setState, handleSubmit, prevStep, couponData }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const onBoardRegister = useSelector(state => state.onBoardRegister);

  useEffect(() => {
    if (onBoardRegister && onBoardRegister.data && onBoardRegister.data.status) {
      dispatch({ type: CREATE_NEW_ONBOARD_USER_RESET });
      confirmCardPayment(onBoardRegister.data.data);
    } else if (onBoardRegister && onBoardRegister.data && !onBoardRegister.data.status) {
      dispatch({ type: CREATE_NEW_ONBOARD_USER_RESET });
      toast.error(onBoardRegister.data.message);
    }
  }, [onBoardRegister, dispatch]);

  const confirmCardPayment = async formData => {
    const { clientId, client_secret, status } = formData;
    if (status === 'requires_action') {
      stripe.confirmCardPayment(client_secret).then(async function (result) {
        if (result.error) {
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          toast.error(result.error);
        } else {
          await dispatch(completeOnboardAction({ clientId, plan: state.step2.selectedPackage.value.plans[0].id }));
          toast.success('Your payment is successful.');
        }
      });
    } else if (status === 'fail') {
      toast.error(formData.msg);
    } else {
      await dispatch(completeOnboardAction({ clientId, plan: state.step2.selectedPackage.value.plans[0].id }));
      toast.success('Your payment is successful.');
    }
  };

  const handlePaymentSubmit = async e => {
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: state.step1.name.value,
        email: state.step1.email.value,
      },
    });

    if (!error) {
      const { id } = paymentMethod;
      const paymentData = { id, promotion_code: couponData.promotionCode && couponData.promotionCode.length ? couponData.promotionCode[0].id : '' };

      // setState(prevVal => ({ ...prevVal, step5: {  paymentData } }));

      if (paymentData.id) {
        handleSubmit(paymentData);
      }
    }
  };

  return (
    <>
      {/* <CardElement /> */}
      <div className='onboard_card_input'>
        <CardNumberElement
          options={{
            showIcon: true,
            style: {
              base: {
                iconColor: '#000000',
                color: '#000000',
                fontWeight: '500',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
                fontSmoothing: 'antialiased',
                padding: '25px',
                ':-webkit-autofill': {
                  color: '#aa9999',
                },
                '::placeholder': {
                  color: '#aa9999',
                  fontWeight: '500',
                },
              },
            },
          }}
        />
      </div>
      <div className='form_section'>
        <div className='onboard_card_input'>
          <CardExpiryElement
            options={{
              showIcon: true,
              style: {
                base: {
                  iconColor: '#000000',
                  color: '#000000',
                  fontWeight: '500',
                  textAlign: 'center',
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '14px',
                  fontSmoothing: 'antialiased',
                  padding: '25px',
                  ':-webkit-autofill': {
                    color: '#aa9999',
                  },
                  '::placeholder': {
                    color: '#aa9999',
                    fontWeight: '500',
                  },
                },
              },
            }}
          />
        </div>
        <div className='onboard_card_input'>
          <CardCvcElement
            options={{
              showIcon: true,
              style: {
                base: {
                  iconColor: '#000000',
                  color: '#000000',
                  fontWeight: '500',
                  textAlign: 'center',
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '14px',
                  fontSmoothing: 'antialiased',
                  padding: '25px',
                  ':-webkit-autofill': {
                    color: '#aa9999',
                  },
                  '::placeholder': {
                    color: '#aa9999',
                    fontWeight: '500',
                  },
                },
                invalid: {
                  iconColor: '#FFC7EE',
                  color: '#FFC7EE',
                },
              },
            }}
          />
        </div>
      </div>

      {onBoardRegister && onBoardRegister.loading ? (
        <div className='onboard_button'>
          <button disabled>Processing...</button>
        </div>
      ) : (
        <div className='onboard_button'>
          <button onClick={prevStep} className='prev'>
            Previous
          </button>
          <button disabled={!stripe} onClick={handlePaymentSubmit}>
            Pay
          </button>
        </div>
      )}
    </>
  );
};

const Payment = ({ prevStep, handleSubmit, state, setState, navigateLogin, couponData, setCouponData }) => {
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState('');
  const [couponError, setCouponError] = useState('');
  const [active, setActive] = useState(false);
  const verfifyCoupon = useSelector(state => state.verfifyCoupon);
  const getAdminCoupon = useSelector(state => state.getAdminCoupon);

  useEffect(() => {
    dispatch(getAdminCouponsAction());
  }, [active]);

  useEffect(() => {
    if (verfifyCoupon && verfifyCoupon.data && verfifyCoupon.data.status) {
      toast.success(verfifyCoupon.data.message);
      setCouponError('');
      setCouponData(verfifyCoupon && verfifyCoupon.data && verfifyCoupon.data.data);
    } else {
      toast.error(verfifyCoupon && verfifyCoupon.data && verfifyCoupon.data.message);
      setCouponError(verfifyCoupon && verfifyCoupon.data && verfifyCoupon.data.message);
    }
  }, [verfifyCoupon]);

  const handleClick = () => {
    if (coupon === '') {
      setCouponError('Please enter coupon');
      setCouponData('');
    } else {
      dispatch(verifyCouponAction(coupon));
    }
  };

  const handleClickCoupon = d => {
    dispatch(verifyCouponAction(d && d.code));
    setActive(false);
    setCoupon(d && d.code);
  };

  return (
    <Elements stripe={stripePromise}>
      <div className='coupon-container'>
        <div className='payment-coupon-container'>
          {/* <CheckOutGpay /> */}
          {verfifyCoupon && verfifyCoupon.data && verfifyCoupon.data.status ? (
            <div style={{ display: 'flex', gap: '1rem', cursor: 'pointer', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <div className='payment_btn_list'>
                  <h1>
                    {state.step2.selectedPackage.value && state.step2.selectedPackage.value.name} - &nbsp;&nbsp;
                    <span style={{ fontWeight: '600', textDecoration: Object.keys(couponData).length > 0 ? 'line-through' : 'none' }}>
                      $ {state.step2.selectedPackage.value.plans[0].amount / 100} per {state.step2.selectedPackage.value.plans[0].interval}
                    </span>
                    &nbsp;&nbsp;
                    <span>
                      {Object.keys(couponData).length > 0 &&
                        couponData &&
                        couponData.promotionCode &&
                        couponData.promotionCode.length > 0 &&
                        couponData.promotionCode[0].coupon && (
                          <Fragment>
                            {couponData.promotionCode[0].coupon.amount_off
                              ? ' $' +
                                Number(state.step2.selectedPackage.value.plans[0].amount / 100 - couponData.promotionCode[0].coupon.amount_off) +
                                ' per ' +
                                state.step2.selectedPackage.value.plans[0].interval
                              : ' $' +
                                Number(
                                  state.step2.selectedPackage.value.plans[0].amount / 100 -
                                    (couponData.promotionCode[0].coupon.percent_off * (state.step2.selectedPackage.value.plans[0].amount / 100)) / 100
                                ) +
                                ' per ' +
                                state.step2.selectedPackage.value.plans[0].interval}
                          </Fragment>
                        )}
                    </span>
                  </h1>
                </div>
                {couponData && couponData.promotionCode && couponData.promotionCode.length > 0 && (
                  <h1 style={{ textTransform: 'uppercase', margin: '0.8rem', color: 'var(--primary-color)' }}>{couponData.promotionCode[0].code}</h1>
                )}
                <p style={{ color: '#000', fontSize: '1.2rem', margin: '0.5rem' }}> Coupon Applied Successfully </p>
              </div>
              <button
                style={{ height: '3rem' }}
                className='table__button table__button--delete'
                onClick={() => {
                  dispatch({ type: VERIFY_COUPON_RESET });
                  setCouponData('');
                }}
              >
                <svg className='table__button--icon-red'>
                  <use xlinkHref={`/assets/sprite.svg#icon-delete`} />
                </svg>
                <span>Remove coupon</span>
              </button>
            </div>
          ) : (
            <div className='onboard_input'>
              <InputCoupon
                type='text'
                placeholder='Enter Coupon Code'
                value={coupon}
                onFocus={e => {
                  setActive(!active);
                }}
                onChange={e => {
                  setCoupon(e.target.value);
                  setCouponError('');
                }}
                errorMessage={couponError}
                label='Apply Coupon'
                icon='ticket'
                handler={handleClick}
              />
              {/* <div style={{ height: '10rem', overflow: 'auto', border: '1px solid #0000003d' }}>
                {getAdminCoupon && getAdminCoupon.data && getAdminCoupon.data.data ? (
                  getAdminCoupon.data.data.map((item, index) => {
                    return (
                      <div className='counpon-detail' key={index}>
                        <div className='counpon-name'>
                          <h2 style={{ textTransform: 'uppercase' }}>{item.code}</h2>
                        </div>
                        <div className='onboard_button' style={{ marginTop: '0' }}>
                          <button onClick={() => handleClickCoupon(item)}>Apply</button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className='counpon-detail'>
                    <Spinner />
                  </div>
                )}
              </div> */}
            </div>
          )}
        </div>
        {/* <div className={active ? 'couponlist-container-active' : 'couponlist-container'}>
          {
            getAdminCoupon &&
              getAdminCoupon.data &&
              getAdminCoupon.data.data ?
              getAdminCoupon.data.data.map((item, index) => {
                return (<div className='counpon-detail' key={index}>
                  <div className='counpon-name'>
                    <h2 style={{ textTransform: 'uppercase' }}>{item.code}</h2>
                  </div>
                  <div className='onboard_button' style={{ marginTop: '0' }}>
                    <button onClick={() => handleClickCoupon(item)}>Apply</button>
                  </div>
                </div>)
              }) :
              <div className='counpon-detail'>
                <Spinner />
              </div>
          }
        </div> */}
      </div>

      <CheckOut prevStep={prevStep} setState={setState} state={state} handleSubmit={handleSubmit} couponData={couponData} navigateLogin={navigateLogin} />
    </Elements>
  );
};

export default Payment;
