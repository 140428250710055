import React from 'react';
import { inputPhoneMasking, unMasking, validateEmail, validatePassword, validatePhone } from '../../utils/validators';
import BaseInput from '../../components/BaseInput/BaseInput';

const SalonInfo = ({ nextStep, state, setState, navigateLogin }) => {
  const handleNextStep = () => {
    if (state.step1.name.value === '') {
      setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, name: { ...prevVal.step1.name, error: 'Please enter name.' } } }));
    }
    if (state.step1.address.value === '') {
      setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, address: { ...prevVal.step1.address, error: 'Please enter address' } } }));
    }
    if (state.step1.email.value === '') {
      setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, email: { ...prevVal.step1.email, error: 'Please enter email' } } }));
    }
    if (!validateEmail(state.step1.email.value)) {
      setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, email: { ...prevVal.step1.email, error: 'Please enter valid email' } } }));
    }
    if (!validatePhone(unMasking(state.step1.phone.value))) {
      setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, phone: { ...prevVal.step1.phone, error: 'Please enter valid phone no.' } } }));
    }
    if (!validatePassword(state.step1.password.value)) {
      setState(prevVal => ({
        ...prevVal,
        step1: { ...prevVal.step1, password: { ...prevVal.step1.password, error: 'Password should have uppercase, lowercase, symbol and 8 characters long.' } },
      }));
    }
    if (state.step1.password.value !== state.step1.passwordConfirm.value) {
      setState(prevVal => ({
        ...prevVal,
        step1: { ...prevVal.step1, passwordConfirm: { ...prevVal.step1.passwordConfirm, error: 'Password does not match' } },
      }));
    }

    if (
      state.step1.name.value &&
      state.step1.email.value &&
      validateEmail(state.step1.email.value) &&
      state.step1.address.value &&
      state.step1.phone.value &&
      validatePassword(state.step1.password.value) &&
      state.step1.password.value == state.step1.passwordConfirm.value
    ) {
      nextStep();
    }
  };

  return (
    <div className='onboard_form'>
      <div className='onboard_form_input'>
        <div>
          <BaseInput
            placeholder={' Enter the name'}
            title={'Salon Name'}
            icon={'icon-new-salon'}
            value={state.step1.name.value}
            onChange={e => {
              setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, name: { value: e.target.value, error: '' } } }));
            }}
          />
          <p className='onboard_error'>{state.step1.name.error}</p>
        </div>
        <div>
          <BaseInput
            placeholder={'Enter the email'}
            title={'Email'}
            icon={'icon-new-email'}
            value={state.step1.email.value}
            onChange={e => {
              setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, email: { value: e.target.value, error: '' } } }));
            }}
          />
          <p className='onboard_error'>{state.step1.email.error}</p>
        </div>
      </div>
      <div className='onboard_form_input'>
        <div>
          <BaseInput
            placeholder={'Enter the phone no.'}
            title={'Phone'}
            icon={'icon-new-phone'}
            value={state.step1.phone.value}
            onChange={e => {
              setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, phone: { value: inputPhoneMasking(e.target.value), error: '' } } }));
            }}
          />
          <p className='onboard_error'>{state.step1.phone.error}</p>
        </div>
        <div>
          <BaseInput
            placeholder={'Enter the address'}
            title={'Address'}
            icon={'icon-new-location'}
            value={state.step1.address.value}
            onChange={e => {
              setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, address: { value: e.target.value, error: '' } } }));
            }}
          />
          <p className='onboard_error'>{state.step1.address.error}</p>
        </div>
      </div>
      <div className='onboard_form_input'>
        <div>
          <BaseInput
            placeholder={'Enter the password'}
            title={'Password'}
            icon={'icon-new-key'}
            value={state.step1.password.value}
            onChange={e => {
              setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, password: { value: e.target.value, error: '' } } }));
            }}
            type='password'
          />
          <p className='onboard_error'>{state.step1.password.error}</p>
        </div>
        <div>
          <BaseInput
            placeholder={'Re-enter password'}
            title={'Re-enter password'}
            icon={'icon-new-key'}
            value={state.step1.passwordConfirm.value}
            onChange={e => {
              setState(prevVal => ({ ...prevVal, step1: { ...prevVal.step1, passwordConfirm: { value: e.target.value, error: '' } } }));
            }}
            type='password'
          />
          <p className='onboard_error'>{state.step1.passwordConfirm.error}</p>
        </div>
      </div>
      <div className='onboard_down'>
        <p onClick={navigateLogin}>Back to login</p>
        <div className='onboard_button'>
          <button onClick={handleNextStep}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default SalonInfo;
