import React from 'react'

const RoundCards = ({ service,color }) => { 
    return (
        <>
            {
                service && service.subService && service.subService.map((item, id) =>
                    <div key={id} className="our_services_inner">
                        <div className='text_price' >
                            <h1 className="our_services_heading" style={{color:color}}>{item.title}</h1>
                            <p className="our_services_heading" style={{color:color}}>{item && item.charges ? "$" + item.charges : ""}</p>
                        </div>

                        <p className="our_services_subheading2">
                            {item.description}
                        </p>

                    </div>

                )
            }

        </>
    )
}

export default RoundCards