import React from 'react'
import Styles from './MyProfileInputComponent.module.css'


const MyProfileInputComponent = ({ type, title, placeholder, value, onChange, errorMessage = false }) => {
    return (
        <>
            {type === 'textarea' ?
                <div className={Styles.textarea_content}>
                    <span className={Styles.title}>{title}</span>
                    <span className={Styles.title} style={{ fontWeight: 300, fontSize: '1rem' }}> ( Optional* )</span>
                    <textarea
                        className={Styles.textarea_box}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange} />
                </div >
                :
                <div className={Styles.content}>
                    <span className={Styles.title}>{title}</span>
                    <input
                        className={Styles.input_box}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange} />
                </div>}
            {errorMessage && <p className={Styles.errorMessage} >{errorMessage}</p>}
        </>
    )
}

export default MyProfileInputComponent
