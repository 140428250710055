export const CREATE_NEW_SUBSCRIPTION_REQUEST = 'CREATE_NEW_SUBSCRIPTION_REQUEST';
export const CREATE_NEW_SUBSCRIPTION_SUCCESS = 'CREATE_NEW_SUBSCRIPTION_SUCCESS';
export const CREATE_NEW_SUBSCRIPTION_FAIL = 'CREATE_NEW_SUBSCRIPTION_FAIL';
export const CREATE_NEW_SUBSCRIPTION_RESET = 'CREATE_NEW_SUBSCRIPTION_RESET';

export const GET_ALL_SUBSCRIPTION_REQUEST = 'GET_ALL_SUBSCRIPTION_REQUEST';
export const GET_ALL_SUBSCRIPTION_SUCCESS = 'GET_ALL_SUBSCRIPTION_SUCCESS';
export const GET_ALL_SUBSCRIPTION_FAIL = 'GET_ALL_SUBSCRIPTION_FAIL';
export const GET_ALL_SUBSCRIPTION_RESET = 'GET_ALL_SUBSCRIPTION_RESET';

export const SUBSCRIPTION_STATUS_REQUEST = 'SUBSCRIPTION_STATUS_REQUEST';
export const SUBSCRIPTION_STATUS_SUCCESS = 'SUBSCRIPTION_STATUS_SUCCESS';
export const SUBSCRIPTION_STATUS_FAIL = 'SUBSCRIPTION_STATUS_FAIL';
export const SUBSCRIPTION_STATUS_RESET = 'SUBSCRIPTION_STATUS_RESET';

export const DELETE_SUBSCRIPTION_REQUEST = 'DELETE_SUBSCRIPTION_REQUEST';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_FAIL = 'DELETE_SUBSCRIPTION_FAIL';
export const DELETE_SUBSCRIPTION_RESET = 'DELETE_SUBSCRIPTION_RESET';

export const GET_ENABLE_SUBSCRIPTION_REQUEST = 'GET_ENABLE_SUBSCRIPTION_REQUEST';
export const GET_ENABLE_SUBSCRIPTION_SUCCESS = 'GET_ENABLE_SUBSCRIPTION_SUCCESS';
export const GET_ENABLE_SUBSCRIPTION_FAIL = 'GET_ENABLE_SUBSCRIPTION_FAIL';
export const GET_ENABLE_SUBSCRIPTION_RESET = 'GET_ENABLE_SUBSCRIPTION_RESET';

export const GET_CANCEL_SUBSCRIPTION_REQUEST = 'GET_CANCEL_SUBSCRIPTION_REQUEST';
export const GET_CANCEL_SUBSCRIPTION_SUCCESS = 'GET_CANCEL_SUBSCRIPTION_SUCCESS';
export const GET_CANCEL_SUBSCRIPTION_FAIL = 'GET_CANCEL_SUBSCRIPTION_FAIL';
export const GET_CANCEL_SUBSCRIPTION_RESET = 'GET_CANCEL_SUBSCRIPTION_RESET';


export const GET_SUBSCRIPTION_DETAILS_REQUEST = 'GET_SUBSCRIPTION_DETAILS_REQUEST';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_FAIL = 'GET_SUBSCRIPTION_DETAILS_FAIL';
export const GET_SUBSCRIPTION_DETAILS_RESET = 'GET_SUBSCRIPTION_DETAILS_RESET';